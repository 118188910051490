import { Injectable } from '@angular/core';
import * as lscache from 'lscache';
import { CurrentUserService } from "./currentUser/CurrentUserService";

const DEFAULT_PAGE_SIZE = 10;

@Injectable()
export class PersistentStorageService {

  private _pageSize: number = DEFAULT_PAGE_SIZE;
  private prevUserId: number | undefined;

  constructor(private currentUserService: CurrentUserService) {

    this.currentUserService.currentUserData.subscribe(userInfo => {

      if (!userInfo) { //if user has logged out - set page size to default
        this._pageSize = DEFAULT_PAGE_SIZE;
        this.prevUserId = undefined;
        return;
      }

      if (userInfo.id !== this.prevUserId) {

        this.prevUserId = userInfo.id;
        const pageSize = lscache.get(`${userInfo.id}-page-size`);

        if (!pageSize || typeof pageSize !== 'number' || pageSize <= 0 || pageSize > 100) {
          this._pageSize = DEFAULT_PAGE_SIZE;
        } else {
          this._pageSize = pageSize;
        }
      }
    });

  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    if (this.currentUserService.userData && this._pageSize !== value) {
      lscache.set(`${this.currentUserService.userData.id}-page-size`, value);
    }
    this._pageSize = value;
  }

}

import * as lscache from 'lscache';
import { CurrentUserService } from "./currentUser/CurrentUserService";
var DEFAULT_PAGE_SIZE = 10;
var PersistentStorageService = /** @class */ (function () {
    function PersistentStorageService(currentUserService) {
        var _this = this;
        this.currentUserService = currentUserService;
        this._pageSize = DEFAULT_PAGE_SIZE;
        this.currentUserService.currentUserData.subscribe(function (userInfo) {
            if (!userInfo) { //if user has logged out - set page size to default
                _this._pageSize = DEFAULT_PAGE_SIZE;
                _this.prevUserId = undefined;
                return;
            }
            if (userInfo.id !== _this.prevUserId) {
                _this.prevUserId = userInfo.id;
                var pageSize = lscache.get(userInfo.id + "-page-size");
                if (!pageSize || typeof pageSize !== 'number' || pageSize <= 0 || pageSize > 100) {
                    _this._pageSize = DEFAULT_PAGE_SIZE;
                }
                else {
                    _this._pageSize = pageSize;
                }
            }
        });
    }
    Object.defineProperty(PersistentStorageService.prototype, "pageSize", {
        get: function () {
            return this._pageSize;
        },
        set: function (value) {
            if (this.currentUserService.userData && this._pageSize !== value) {
                lscache.set(this.currentUserService.userData.id + "-page-size", value);
            }
            this._pageSize = value;
        },
        enumerable: true,
        configurable: true
    });
    return PersistentStorageService;
}());
export { PersistentStorageService };


  <div class="pagination-wrapper">
      <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [maxSize]="10"
          (pageChange)="handlePageChange()"
          [pageSize]="pageSize"
      ></ngb-pagination>
      <div class="drop-down-wrapper" ngbDropdown [placement]="dropdownPlacement">
          <a class="dropdown-link" ngbDropdownToggle>{{pageSize}} per page</a>
          <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="handlePageSizeChange(10)">10</button>
              <button class="dropdown-item" (click)="handlePageSizeChange(20)">20</button>
              <button class="dropdown-item" (click)="handlePageSizeChange(50)">50</button>
          </div>
      </div>
  </div>
  